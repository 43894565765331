import React from "react"
import Layout from "../../components/layout"
import TextSection from "../../components/project/intro/text-section"
import ProjectDetails from "../../components/project/intro/project-details"
import Spread from "../../components/project/spread"
import {
  Hero,
  Image,
  ImageCaption,
  FullWidthImage,
  Close,
  SpreadText,
  SpreadImageGrid,
  projectWrapper,
} from "../../styles/project-styles"
import SpreadTitle from "../../components/project/spread-title"
import CaptionLeft from "../../components/project/caption-left"
import Understand from "../../components/project/process/understand"
import Design from "../../components/project/process/design"
import Prototype from "../../components/project/process/prototype"
import Img from "gatsby-image"
import SmoothScroll from "../../components/smooth-scroll"
import { motion } from "framer-motion"

const Nomad = ({ data }) => {
  const {
    nomadHero,
    jorgeRamirez1,
    grantRichie,
    calebMartin,
    useCase,
    rpi,
    nomadPosterSession,
    nomadArchDiagram,
    nomadPoster,
  } = data
  return (
    <motion.div
      variants={projectWrapper}
      initial="open"
      animate="open"
      exit="close"
    >
      <Close to="/work/" />
      <Layout project>
        {/* Hero */}
        <Spread fluid>
          <Hero
            gridRowEnd={3}
            imgUrl={nomadHero.childImageSharp.fluid.src}
            aspectRatio={1920 / 1080}
          />
        </Spread>

        <Spread>
          {/* place in data folder */}
          <SpreadText colStart={8} colEnd="span 5" even>
            <TextSection
              title="Challenge"
              text="Modern IoT applications are computationally monolithic and built assuming a 'flat' computing architecture, where processing and inference on data from edge devices is done exclusively on the cloud. With growing computation power on the edge, increasingly remote edge deployments and data privacy concerns, there is a need to push out portions of the processing pipeline closer to the edge."
            />
            <TextSection
              title={"Outcome"}
              text="The outcome of this reasearch project is a software framework called Nomad. Nomad can intelligently partition and schedule data pipelines across the edge-cloud continuum with minimal developer effort"
              style={{ paddingTop: "2.5rem" }}
            />
            <ProjectDetails
              type={"IoT, computer systems research"}
              contributions={"software architecture, software engineering"}
              rowStart={3}
              alignSelf="end"
            />
          </SpreadText>
          <SpreadImageGrid colStart={1} colEnd="span 7" rowStart={1}>
            <Image colEnd="span 6" rowStart={1} rowEnd={"span 3"}>
              <Img fluid={jorgeRamirez1.childImageSharp.fluid} />
            </Image>
            <Image colStart={1} colEnd="span 3" rowStart={4} rowEnd="span 3">
              <Img fluid={grantRichie.childImageSharp.fluid} />
              <ImageCaption>source: unsplash</ImageCaption>
            </Image>
            <Image colStart={4} colEnd="span 3" rowStart={4} rowEnd="span 3">
              <Img fluid={calebMartin.childImageSharp.fluid} />
            </Image>
          </SpreadImageGrid>
        </Spread>

        <Spread>
          <SpreadTitle
            text="The Process"
            colStart={1}
            colEnd="span 4"
            rowStart={1}
            rowEnd="span 1"
          />

          <SpreadText colEnd="span 4">
            <Understand text="Developing distributed IoT applications is difficult: you need working knowledge of sensors and actuators, a good grasp of computer networking and of security. The goal for this research project was thus to make it easy for developers to deploy IoT applications on a cluster of heterogeneous compute resources.To guide our work we used an image recognition system as our target application." />
            <Design text="Nomad was designed with usability as a top priority. The entry-point to the system is the Nomad Client Library that provides a developer-friendly python API for writing and submitting pipelines to the nomad cluster. All pipeline submissions are received by the Nomad Master component, which is responsible for scheduling pipelines and managing the cluster compute resources. After computing the optimal placement of the pipeline, the nomad master will launch the pipeline and orchestrate its execution." />
            <Prototype text="The core Nomad code was written in Python. To simplify deployment we used docker containers  and we used Kubernetes for orchestration. My most notable contributions to the codebase were: the architecture-aware pipeline scheduler, automated pipeline profiling and the nomad client library. To test the system end-to-end we constructed a simple facial recognition pipeline using a raspberry-pi with a camera attached. The pipeline was partitioned between the RPI and our laptops." />
          </SpreadText>
          <SpreadImageGrid colStart={6} colEnd="span 7">
            <Image colStart={1} colEnd="span 7" rowStart={1} rowEnd="span 4">
              <Img fluid={useCase.childImageSharp.fluid} />
            </Image>

            <Image colStart={1} colEnd="span 3" rowStart={5} rowEnd="span 2">
              <Img fluid={rpi.childImageSharp.fluid} />
            </Image>
            <Image colStart={4} colEnd="span 4" rowStart={5} rowEnd="span 2">
              <Img fluid={nomadPosterSession.childImageSharp.fluid} />
            </Image>
          </SpreadImageGrid>
        </Spread>
        <Spread>
          <FullWidthImage>
            <Img fluid={nomadArchDiagram.childImageSharp.fluid} />
          </FullWidthImage>
          <ImageCaption>Nomad architecture diagram</ImageCaption>
        </Spread>
        <Spread>
          <FullWidthImage>
            <Img fluid={nomadPoster.childImageSharp.fluid} />
            <ImageCaption>Nomad poster</ImageCaption>
          </FullWidthImage>
        </Spread>
      </Layout>
    </motion.div>
  )
}

export default Nomad

export const pageQuery = graphql`
  query {
    nomadHero: file(relativePath: { eq: "nomad/nomad-hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jorgeRamirez1: file(
      relativePath: { eq: "nomad/jorge-ramirez-bIfbx3IiwVU-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    grantRichie: file(
      relativePath: { eq: "nomad/grant-ritchie-n_wXNttWVGs-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    calebMartin: file(
      relativePath: { eq: "nomad/caleb-martin-fI4aMg1vwFY-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    useCase: file(relativePath: { eq: "nomad/artboard-24.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rpi: file(relativePath: { eq: "nomad/rpi.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    nomadPosterSession: file(
      relativePath: { eq: "nomad/nomad-poster-session.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    nomadArchDiagram: file(relativePath: { eq: "nomad/arch.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    nomadPoster: file(relativePath: { eq: "nomad/nomad-poster.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
